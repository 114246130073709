import {
    Config,
    Circle,
    Ellipse,
    Range,
    Rectangle,
    randomInt,
    Triangle,
    Line,
    Style,
    ColorPalette,
    Text,
    Path,
    BuiltinPalettes
} from '@dyssent/blankjs';

const names = require('./names.json');

export function randomConfig(): Config {
    const shapesCount = Math.floor(Math.random() * 3) + 1;
    const res: Config = {
        shapes: [],
        palettes: {}
    };

    for (let i = 0; i < shapesCount; i++) {
        const shapeType = Math.floor(Math.random() * 7);
        switch (shapeType) {
            case 0: res.shapes.push(randomCircle()); break;
            case 1: res.shapes.push(randomEllipse()); break;
            case 2: res.shapes.push(randomRectangle()); break;
            case 3: res.shapes.push(randomTriangle()); break;
            case 4: res.shapes.push(randomLine()); break;
            case 5: res.shapes.push(randomText()); break;
            case 6: res.shapes.push(randomPath()); break;
        }
    }

    return res;
}

function randomRange(left: number, right: number, spread: number, step?: number): Range {
    return {
        left: randomInt(left, left + spread),
        right: randomInt(right, right + spread),
        step
    };
}

function maybeRange(left: number, right: number, spread: number, step?: number): Range | undefined {
    return Math.random() > 0.5 ? randomRange(left, right, spread, step) : undefined;
}

function randomPalette(): ColorPalette {
    const all = Object.keys(BuiltinPalettes);
    return {
        type: 'palette',
        palette: {
            type: 'builtin',
            id: all[Math.floor(Math.random() * all.length)]
        }
    };
}

function randomStyle(noFill?: boolean): Style {
    const withStroke = Math.random() > 0.5 || noFill;
    const withFill = !noFill && Math.random() > 0.5;
    const withOpacity = Math.random() > 0.5;
    return {
        fill: withFill || !withStroke ? randomPalette() : undefined,
        strokeWidth: withStroke ? randomRange(1,3,1) : undefined,
        stroke: withStroke ? randomPalette() : undefined,
        opacity: withOpacity ? randomRange(noFill ? 0.5 : 0, 1, 0) : undefined
    };
}

export function randomCircle(): Circle {
    
    return {
        type: 'circle',
        radius: randomRange(2, 8, 4, Math.random() > 0.5 ? 2 : undefined),
        style: randomStyle(),
        modifiers: {
            replication: {
                count: randomRange(1, 5, 0)
            },
            transforms: {
                translate: {
                    modifier: 'translate',
                    x: randomRange(-64, 64, 0, 8),
                    y: randomRange(-64, 64, 0, 8),
                }
            }
        }
    };
}

export function randomEllipse(): Ellipse {
    return {
        type: 'ellipse',
        width: randomRange(2, 8, 4, Math.random() > 0.5 ? 2 : undefined),
        height: randomRange(2, 8, 4, Math.random() > 0.5 ? 2 : undefined),
        style: randomStyle(),
        modifiers: {
            replication: {
                count: randomRange(1, 5, 0)
            },
            transforms: {
                translate: {
                    modifier: 'translate',
                    x: randomRange(-64, 64, 0, 8),
                    y: randomRange(-64, 64, 0, 8),
                }
            }
        }
    };
}

export function randomRectangle(): Rectangle {
    return {
        type: 'rectangle',
        width: randomRange(12, 36, 4, Math.random() > 0.5 ? 2 : undefined),
        height: randomRange(12, 36, 4, Math.random() > 0.5 ? 2 : undefined),
        borderRadius: {
            left: 5,
            right: 5,
        },
        style: randomStyle(),
        modifiers: {
            replication: {
                count: randomRange(1, 5, 0)
            },
            transforms: {
                translate: {
                    modifier: 'translate',
                    x: randomRange(-64, 64, 0, 8),
                    y: randomRange(-64, 64, 0, 8),
                }
            }
        }
    };
}


export function randomTriangle(): Triangle {
    return {
        type: 'triangle',
        length1: randomRange(12, 24, 4, Math.random() > 0.5 ? 2 : undefined),
        length2: maybeRange(12, 24, 4, Math.random() > 0.5 ? 2 : undefined),
        length3: maybeRange(12, 24, 4, Math.random() > 0.5 ? 2 : undefined),
        style: randomStyle(),
        modifiers: {
            replication: {
                count: randomRange(1, 5, 0)
            },
            transforms: {
                translate: {
                    modifier: 'translate',
                    x: randomRange(-64, 64, 0, 8),
                    y: randomRange(-64, 64, 0, 8),
                }
            }
        }
    };
}

export function randomText(): Text {
    return {
        type: 'text',
        size: randomRange(12, 24, 4, Math.random() > 0.5 ? 2 : undefined),
        style: randomStyle(true),
        modifiers: {
            replication: {
                count: randomRange(1, 5, 0)
            },
            transforms: {
                translate: {
                    modifier: 'translate',
                    x: randomRange(-64, 64, 0, 8),
                    y: randomRange(-64, 64, 0, 8),
                }
            }
        },
        text: undefined
    };
}

export function randomLine(): Line {
    return {
        type: 'line',
        length: randomRange(24, 256, 4, Math.random() > 0.5 ? 2 : undefined),
        style: randomStyle(true),
        modifiers: {
            replication: {
                count: randomRange(1, 5, 0)
            },
            transforms: {
                translate: {
                    modifier: 'translate',
                    x: randomRange(-64, 64, 0, 8),
                    y: randomRange(-64, 64, 0, 8),
                },
                rotate: {
                    modifier: 'rotate',
                    angle: randomRange(180, 180, 180),
                    origin: {
                        x: {
                            left: 64,
                            right: 64
                        },
                        y: {
                            left: 64,
                            right: 64
                        }
                    }
                }
            }
        }
    };
}

const PathDemo = [
    `M73.2 72.6c-1-7-8.5-9.3-14.3-11 -3.1-0.9-7-1.6-9.5-3.7C47.3 56.1 47.5 50.9 49 49.8c3.3-2.4 5.3-7.5 6.1-11.2 0.3-1.1-0.4-0.5 0.8-0.6 0.7-0.1 2.1-2.3 2.6-3.2 1-1.8 2.4-4.8 2.4-8 0-2.2-1.1-2.5-2.4-2.3 -0.4 0.1-0.9 0.2-0.9 0.2 1.4-1.8 1.7-8.2-0.7-13.4C53.6 3.7 44.4 2 40 2c-2.3 0-7.6 1.6-7.8 3.3 -3.4 0.2-7.7 2.6-9.2 5.9 -2.4 5.4-1.5 11.4-0.7 13.4 0 0-0.5-0.1-0.9-0.2 -1.3-0.2-2.4 0.1-2.4 2.3 0 3.2 1.4 6.2 2.4 8 0.5 0.9 1.9 3.1 2.6 3.2 1.2 0.2 0.6-0.4 0.8 0.6 0.9 3.7 3.1 9 6.1 11.3 1.7 2 1.7 6.3-0.4 8.1 -2.8 2.4-7.4 3.1-10.8 4.1 -5.9 1.8-13.3 4.9-13.3 12.3C6.5 76.7 7.7 78 10.1 78h59.7c2.4 0 3.7-1.3 3.6-3.7C73.5 74.3 73.4 73.8 73.2 72.6 72.6 68.7 73.4 73.8 73.2 72.6z`,
    `M71 74.6c-0.1-5.4-4.2-7.7-8.8-9.6 -3.6-1.5-12.1-2.2-13.8-6.2 -0.8-1.9-0.2-3.1 0.1-5.8 7 0.2 17.7-5.3 19-10.4 0.7-2.5-2.8-4.4-4.2-6 -1.9-2-3.3-4.5-4.3-7.1 -2.2-5.6-0.2-11.2-2.1-16.7C54.2 5.1 46.5 2 40 2l0 0c0 0 0 0 0 0s0 0 0 0l0 0c-6.5 0-14.2 3.1-16.8 10.7 -1.9 5.5 0 11.1-2.1 16.7 -1 2.6-2.4 5-4.3 7.1 -1.4 1.6-4.9 3.5-4.2 6 1.3 5.1 12.1 10.6 19 10.4 0.3 2.7 0.9 4 0.1 5.9 -1.7 4-10.2 4.7-13.8 6.2 -4.6 2-8.7 4.2-8.8 9.6 0 2.8 0 3.4 3.5 3.4H67.5C71 78 71 77.4 71 74.6z`
];

export function randomPath(): Path {
    return {
        type: 'path',
        style: randomStyle(true),
        modifiers: {
            replication: {
                count: randomRange(1, 3, 0)
            },
            transforms: {
                translate: {
                    modifier: 'translate',
                    x: randomRange(-64, 64, 0, 8),
                    y: randomRange(-64, 64, 0, 8),
                }
            }
        },
        data: PathDemo[randomInt(0, PathDemo.length - 1)]
    };
}

export function randomName(): string {
    const first = randomInt(0, names.length - 1);
    const last = randomInt(0, names.length - 1);
    return names[first].first + ' ' + names[last].last;
}

export function getName(index: number): string {
    while(index >= names.length) {
        index -= names.length;
    }
    return names[index].first + ' ' + names[index].last;
}
