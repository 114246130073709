import React from 'react';
import { Replicate, Modifiers, Translate, Rotate, Scale } from '@dyssent/blankjs';
import { ReplicationEdit } from './ReplicationEdit';
import { TranslateEdit } from './TranslateEdit';
import { RotateEdit } from './RotateEdit';
import { ScaleEdit } from './ScaleEdit';

export interface ModifiersEditProps {
    mods: Modifiers;
    onChanged: (mod: Modifiers) => void;
}

export function ModifiersEdit(props: ModifiersEditProps) {
    const { mods, onChanged } = props;

    function onReplicateUpdated(rep: Replicate) {
        onChanged({
            ...mods,
            replication: rep
        });
    }

    function onTranslateUpdated(tr: Translate) {
        onChanged({
            ...mods,
            transforms: {
                ...mods.transforms,
                translate: tr
            }
        });
    }

    function onRotateUpdated(rot: Rotate) {
        onChanged({
            ...mods,
            transforms: {
                ...mods.transforms,
                rotate: rot
            }
        });
    }

    function onScaleUpdated(scale: Scale) {
        onChanged({
            ...mods,
            transforms: {
                ...mods.transforms,
                scale
            }
        });
    }


    return (
        <div
            style={{
                marginTop: 20
            }}
        >
            <h5 className="bp3-text-muted bp3-heading">Modifiers:</h5>
            <ReplicationEdit
                replication={mods.replication}
                onUpdated={onReplicateUpdated}
            />
            <TranslateEdit
                translate={mods.transforms.translate}
                onUpdated={onTranslateUpdated}
            />
            <RotateEdit
                rotate={mods.transforms.rotate}
                onUpdated={onRotateUpdated}
            />
            <ScaleEdit
                scale={mods.transforms.scale}
                onUpdated={onScaleUpdated}
            />
        </div>
    );
}
