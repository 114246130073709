import React from 'react';

import { SizesPreviewRow, SizesPreviewContainer } from './styles';
import { Config, Blank } from '@dyssent/blankjs';
import { getName } from '../Configurator';

export interface PreviewProps {
    config: Config;
    idOffset: number;
}

export function SizesPreview(props: PreviewProps) {
    const { config, idOffset } = props;
    const previews = 5;
    const rows: JSX.Element[] = [];
    for (let i = 0; i < previews; i++) {
        const name = getName(i);
        rows.push(
            <SizesPreviewRow key={i + idOffset}>
                <Blank
                    className="blank blank-square blank-shadow"
                    id={i + idOffset}
                    config={config}
                    style={{
                        width: 128,
                        height: 128
                    }}
                    title={name}
                />
                <Blank
                    className="blank blank-square blank-shadow"
                    id={i + idOffset}
                    config={config}
                    style={{
                        width: 64,
                        height: 64
                    }}
                    title={name}
                />
                <Blank
                    className="blank blank-square blank-shadow"
                    id={i + idOffset}
                    config={config}
                    style={{
                        width: 32,
                        height: 32
                    }}
                    title={name}
                />
                <Blank
                    className="blank blank-square blank-shadow"
                    id={i + idOffset}
                    config={config}
                    style={{
                        width: 16,
                        height: 16
                    }}
                    title={name}
                />
            </SizesPreviewRow>
        );
    }
    return (
        <SizesPreviewContainer>
            {
                rows
            }
        </SizesPreviewContainer>
    );
}
