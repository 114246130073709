import React, { useMemo } from 'react';
import { BuiltinPalettes, PaletteNine, Color, Palette } from '@dyssent/blankjs';
import { Switch, Popover, Button, Icon, MenuItem } from '@blueprintjs/core';
import { Select, IItemRendererProps } from '@blueprintjs/select';
import { SketchPicker, ColorResult } from 'react-color';

export interface ColorEditProps {
    title: string;
    color?: Color;
    userPalettes: {[key: string]: Palette};
    onChanged: (color?: Color) => void;
}

const defaultColor: Color = {
    type: 'palette',
    palette: {
        type: 'builtin',
        id: PaletteNine.id
    }
};

const defaultCustomColor: Color = {
    type: 'custom',
    value: '111111'
};

type PaletteItem = { type: 'normal', palette: Palette, color: Color} | { type: 'custom'};
const ColorSelect = Select.ofType<PaletteItem>();

export function ColorEdit(props: ColorEditProps) {
    const { color, title, userPalettes, onChanged } = props;

    const [palettes, palettesMap] = useMemo<[Array<PaletteItem>, {[key: string]: Palette}]>(() => {
        const toItem = (builtin: boolean, p: Palette): PaletteItem => ({
            type: 'normal',
            palette: p,
            color: {
                type: 'palette',
                palette: {
                    type: builtin ? 'builtin' : 'provided',
                    id: p.id
                }
            }
        });

        return [[
            ...Object.values(userPalettes).map(p => toItem(false, p)),
            ...Object.values(BuiltinPalettes).map(p => toItem(true, p)),
            {
                type: 'custom'
            }
        ], {
            ...BuiltinPalettes,
            ...userPalettes
        }];
    }, [userPalettes]);

    function onSwitchChange() {
        if (color) {
            onChanged(undefined);
        } else {
            onChanged(
                color ? color : defaultColor
            )
        }
    }

    function onCustomColorChange(color: ColorResult) {
        onChanged({
            type: 'custom',
            value: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a ? color.rgb.a : 1})`
        });
    }

    function onColorSelect(item: PaletteItem | null) {
        if (!item) {
            return;
        }
        if (item.type === 'custom') {
            onChanged({
                ...defaultCustomColor
            });
            return;
        }
        onChanged(item.color);
    }

    function colorRenderer(item: PaletteItem, itemProps: IItemRendererProps) {
        const values = item.type === 'custom' ? 1 : item.palette.values.length;
        const valuePercentage = 100 / values; 
        const background = item.type === 'custom' ? 'transparent' :
            `linear-gradient(90deg, ${item.palette.values.map((v, vi) => `${v} ${valuePercentage * vi}%${vi !== values - 1 ? `, ${v} ${valuePercentage * (vi + 1)}%` : ''}`).join(', ')}`

        return (
            <MenuItem
                key={itemProps.index}
                active={itemProps.modifiers.active}
                disabled={itemProps.modifiers.disabled}
                text={item.type !== 'custom' ? item.palette.name : 'Custom...'}
                onClick={itemProps.handleClick}
                icon={
                    <div style={{
                        width: 32,
                        height: 32,
                        background
                    }} />
                }
            />
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                height: 32
            }}
        >
            <Switch
                checked={color ? true : false}
                onChange={onSwitchChange}
                label={title}
                style={{
                    marginBottom: 0,
                    marginRight: 10,
                    width: 100
                }}
            />
            {
                color &&
                <>
                    <ColorSelect
                        items={palettes}
                        onItemSelect={onColorSelect}
                        itemRenderer={colorRenderer}
                        filterable={false}
                        activeItem={color.type === 'custom' ? palettes[palettes.length - 1] as any : palettes.find(p => p.type === 'normal' && p.palette.id === color.palette.id)}
                        onActiveItemChange={onColorSelect}
                    >
                        <Button
                            minimal={true}
                            style={{
                                minWidth: 120,
                                marginRight: 10
                            }}
                        >
                            {color.type === 'custom' ? 'Custom' : `Palette: ${palettesMap[color.palette.id].name}`}
                        </Button>
                    </ColorSelect>
                    {
                        color && color.type === 'custom' &&
                        <Popover
                            content={
                                <SketchPicker
                                    color={color.value}
                                    onChangeComplete={onCustomColorChange}
                                />
                            }
                        >
                            <Button icon={<Icon color={color.value} icon="tint" />} />
                        </Popover>
                    }
                </>
            }
            
        </div>
    );
}
