import React from 'react';
import { Range, Rotate } from '@dyssent/blankjs';
import { RangeEdit } from './RangeEdit';

export interface RotateEditProps {
    rotate?: Rotate;
    onUpdated: (tr: Rotate) => void;
}

const defaultRotate: Rotate = {
    modifier: 'rotate',
    origin: undefined,
    angle: {
        left: 0,
        right: 0
    }
};

const defaultOrigin = {
    x: {
        left: 0,
        right: 0
    },
    y: {
        left: 0,
        right: 0
    }
}

export function RotateEdit(props: RotateEditProps) {
    const { rotate = defaultRotate, onUpdated } = props;
    const origin = rotate.origin || defaultOrigin;

    function onAngleUpdate(range: Range) {
        onUpdated({
            ...rotate,
            angle: range
        });
    }

    function onOriginXUpdate(range: Range) {
        onUpdated({
            ...rotate,
            origin: {
                ...origin,
                x: range
            }
        });
    }

    function onOriginYUpdate(range: Range) {
        onUpdated({
            ...rotate,
            origin: {
                ...origin,
                y: range
            }
        });
    }

    return (
        <div>
            <span>Rotate: </span>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: 20,
                    paddingRight: 20
                }}
            >
                <RangeEdit title="Angle" muted={true} range={rotate.angle} onUpdated={onAngleUpdate} min={0} max={360}/>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gridGap: 20
                    }}
                >
                    <RangeEdit title="Origin X" muted={true} range={origin.x} onUpdated={onOriginXUpdate} />
                    <RangeEdit title="Origin Y" muted={true} range={origin.y} onUpdated={onOriginYUpdate} />
                </div>
            </div>
        </div>
    );
}
