import React from 'react';
import { Path } from '@dyssent/blankjs';
import { TextArea } from '@blueprintjs/core';

export interface PathEditProps {
    path: Path;
    onChanged: (path: Path) => void;
}

export function PathEdit(props: PathEditProps) {
    const { path, onChanged } = props;

    function onDataUpdate(event: React.ChangeEvent<HTMLTextAreaElement>) {
        onChanged({
            ...path,
            data: event.currentTarget.value
        });
    }

    return (
        <TextArea
            fill={true}
            growVertically={true}
            large={true}
            onChange={onDataUpdate}
            value={path.data}
        />
    );
}
