import React from 'react';
import { Range, Text } from '@dyssent/blankjs';
import { RangeEdit } from './RangeEdit';
import { TextArea } from '@blueprintjs/core';

export interface TextEditProps {
    text: Text;
    onChanged: (text: Text) => void;
}

export function TextEdit(props: TextEditProps) {
    const { text, onChanged } = props;

    function onSizeChanged(range: Range) {
        onChanged({
            ...text,
            size: range
        });
    }

    function onTextChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        const v = event.currentTarget.value;
        const pieces = v.split('\n');
        onChanged({
            ...text,
            text: v === '' ? undefined : pieces.length > 1 ? pieces : v
        });
    }

    const textValue = !text.text ? '' :
        Array.isArray(text.text) ? text.text.join('\n') : text.text;

    return (
        <>
            <RangeEdit title="Font Size" range={text.size} onUpdated={onSizeChanged} min={0} />
            <TextArea
                fill={true}
                growVertically={true}
                large={true}
                onChange={onTextChange}
                value={textValue}
            />
        </>
    );
}
