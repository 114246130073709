import React from 'react';
import { Range, Line } from '@dyssent/blankjs';
import { RangeEdit } from './RangeEdit';

export interface LineEditProps {
    line: Line;
    onChanged: (line: Line) => void;
}

export function LineEdit(props: LineEditProps) {
    const { line, onChanged } = props;

    function onLengthUpdate(length: Range) {
        onChanged({
            ...line,
            length
        });
    }

    return (
        <div
            style={{
                marginLeft: 20
            }}
        >
            <RangeEdit title="Length" muted={true} range={line.length} onUpdated={onLengthUpdate} min={0} />
        </div>
    );
}
