import React, { useState } from 'react';

import { EditorContainer } from './styles';
import { Configurator } from './Configurator';
import { Preview } from './Preview';
import { ConfigPreview } from './ConfigPreview';
import { Config } from '@dyssent/blankjs';

const startConfig: Config = {
    "shapes": [
      {
        "type": "ellipse",
        "width": {
          "left": 4,
          "right": 10
        },
        "height": {
          "left": 5,
          "right": 11,
          "step": 2
        },
        "style": {
          "strokeWidth": {
            "left": 4,
            "right": 8
          },
          "stroke": {
            "type": "palette",
            "palette": {
              "type": "builtin",
              "id": "cobalt5"
            }
          },
          "opacity": {
            "left": 0.2,
            "right": 0.5
          }
        },
        "modifiers": {
          "replication": {
            "count": {
              "left": 2,
              "right": 4
            }
          },
          "transforms": {
            "translate": {
              "modifier": "translate",
              "x": {
                "left": -64,
                "right": 64,
                "step": 8
              },
              "y": {
                "left": -64,
                "right": 64,
                "step": 8
              }
            },
            "scale": {
              "modifier": "scale",
              "x": {
                "left": 1,
                "right": 1
              }
            }
          }
        }
      },
      {
        "type": "text",
        "size": {
          "left": 36,
          "right": 64,
          "step": 2
        },
        "style": {
          "fill": {
            "type": "palette",
            "palette": {
              "type": "builtin",
              "id": "orange5"
            }
          },
          "strokeWidth": {
            "left": 1,
            "right": 4
          }
        },
        "modifiers": {
          "replication": {
            "count": {
              "left": 2,
              "right": 3
            }
          },
          "transforms": {
            "translate": {
              "modifier": "translate",
              "x": {
                "left": -32,
                "right": 32,
                "step": 8
              },
              "y": {
                "left": -32,
                "right": 32,
                "step": 8
              }
            },
            "scale": {
              "modifier": "scale",
              "x": {
                "left": 1,
                "right": 1
              }
            },
            "rotate": {
              "modifier": "rotate",
              "origin": {
                "x": {
                  "left": 64,
                  "right": 64
                },
                "y": {
                  "left": 64,
                  "right": 64
                }
              },
              "angle": {
                "left": -45,
                "right": 45,
                "step": 15
              }
            }
          }
        }
      }
    ],
    "palettes": {}
};

export interface EditorProps {
}

export function Editor(props: EditorProps) {
    const [config, setConfig] = useState(() => JSON.parse(JSON.stringify(startConfig)));

    function onConfigUpdated(cfg: Config) {
        setConfig(cfg);
    }

    return (
        <>
            <EditorContainer>
                <Configurator
                    config={config}
                    onUpdated={onConfigUpdated}
                />
                <Preview
                    config={config}
                />
            </EditorContainer>
            <ConfigPreview config={config} />
        </>
    );
}
