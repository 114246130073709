import React, { useState, useEffect } from 'react';

import { ConfiguratorContainer } from './styles';
import { Button, Tooltip, Popover, Menu, MenuItem } from '@blueprintjs/core';
import { Config, Shape, Palette, PaletteNine } from '@dyssent/blankjs';
import {
    randomCircle,
    randomConfig,
    randomRectangle,
    randomEllipse,
    randomTriangle,
    randomLine,
    randomText,
    randomPath
} from './utils';
import { ShapeEditor } from './ShapeEditor';
import { PaletteEditor } from './PaletteEditor';

export interface ConfiguratorProps {
    config: Config;
    onUpdated: (config: Config) => void;
}

export function Configurator(props: ConfiguratorProps) {
    const { onUpdated } = props;
    const [config, setConfig] = useState<Config>(() => {
        return JSON.parse(JSON.stringify(props.config)) as Config;
    });

    function addPalette() {
        const id = Date.now().toString();
        setConfig({
            ...config,
            palettes: {
                ...config.palettes,
                [id]: {
                    id,
                    type: 'values',
                    name: 'New Palette',
                    values: []
                }
            }
        });
    }

    function addShape(s: 'circle' | 'ellipse' | 'rectangle' | 'triangle' | 'line' | 'path' | 'text') {
        return () => {
            let shape: Shape | undefined;
            switch (s) {
                case 'circle':
                    shape = randomCircle();
                    break;
                case 'ellipse':
                    shape = randomEllipse();
                    break;
                case 'rectangle':
                    shape = randomRectangle();
                    break;
                case 'triangle':
                    shape = randomTriangle();
                    break;
                case 'line':
                    shape = randomLine();
                    break;
                case 'path':
                    shape = randomPath();
                    break;
                case 'text':
                    shape = randomText();
                    break;
            }

            if (!shape) {
                return;
            }

            setConfig({
                ...config,
                shapes: [
                    ...config.shapes,
                    shape
                ]
            });
        };
    }

    function onDeleteShape(shape: Shape) {
        setConfig({
            ...config,
            shapes: config.shapes.filter(s => s !== shape)
        });
    }

    function onDuplicateShape(shape: Shape) {
        const copy = JSON.parse(JSON.stringify(shape)) as Shape;
        setConfig({
            ...config,
            shapes: [
                ...config.shapes,
                copy
            ]
        });
    }

    function onMoveDownShape(shape: Shape) {
        const index = config.shapes.indexOf(shape);
        if (index === config.shapes.length - 1) {
            return;
        }

        const newArr: Shape[] = [];
        for(let i = 0; i < config.shapes.length; i++) {
            if (i === index) {
                newArr.push(config.shapes[i + 1]);
            } else
            if (i === index + 1) {
                newArr.push(shape);
            } else {
                newArr.push(config.shapes[i]);
            }
        }

        setConfig({
            ...config,
            shapes: newArr
        });
    }

    function onMoveUpShape(shape: Shape) {
        const index = config.shapes.indexOf(shape);
        if (index === 0) {
            return;
        }

        const newArr: Shape[] = [];
        for(let i = 0; i < config.shapes.length; i++) {
            if (i === index - 1) {
                newArr.push(shape);
            } else
            if (i === index) {
                newArr.push(config.shapes[i - 1]);
            } else {
                newArr.push(config.shapes[i]);
            }
        }

        setConfig({
            ...config,
            shapes: newArr
        });
    }

    function handleShapeChange(index: number) {
        return (shape: Shape) => {
            setConfig({
                ...config,
                shapes: config.shapes.map((s, si) => si === index ? shape : s)
            });
        };
    }

    function handlePaletteChange(pid: string) {
        return (p: Palette) => {
            setConfig({
                ...config,
                palettes: {
                    ...config.palettes,
                    [pid]: p
                }
            });
        };
    }

    function onDeletePalette(p: Palette) {
        const copy = {
            ...config.palettes
        };
        delete copy[p.id];
        setConfig({
            ...config,
            shapes: config.shapes.map(s => {
                if (s.style.fill && s.style.fill.type === 'palette' &&
                    s.style.fill.palette.id === p.id) {
                    s.style.fill.palette = {
                        type: 'builtin',
                        id: PaletteNine.id
                    }
                }
                if (s.style.stroke && s.style.stroke.type === 'palette' &&
                    s.style.stroke.palette.id === p.id) {
                    s.style.stroke.palette = {
                        type: 'builtin',
                        id: PaletteNine.id
                    }
                }
                return {
                    ...s
                };
            }),
            palettes: copy
        });
    }

    function onDuplicatePalette(p: Palette) {
        const copy = JSON.parse(JSON.stringify(p)) as Palette;
        copy.id = Date.now().toString();
        setConfig({
            ...config,
            palettes: {
                ...config.palettes,
                [copy.id]: copy
            }
        });
    }

    function onRandom() {
        setConfig(randomConfig());
    }

    useEffect(() => {
        if (props.config !== config) {
            JSON.parse(JSON.stringify(props.config));
        }
    }, [config, props.config]);

    useEffect(() => {
        onUpdated(config);
    }, [onUpdated, config]);

    return (
        <ConfiguratorContainer>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <h3 className="bp3-heading">Style Config</h3>
                <Tooltip
                    content="Randomize"
                >
                    <Button minimal={true} icon="random" onClick={onRandom} />
                </Tooltip>
            </div>
            {
                config.shapes.length === 0 ?
                    <span className="bp3-text-muted">
                        Add some shapes to get started
                    </span>
                :
                    config.shapes.map((s, si) => {
                        return (
                            <ShapeEditor
                                key={si}
                                shape={s}
                                userPalettes={config.palettes}
                                onChanged={handleShapeChange(si)}
                                onDelete={onDeleteShape}
                                onMoveDown={onMoveDownShape}
                                onMoveUp={onMoveUpShape}
                                onDuplicate={onDuplicateShape}
                            />
                        );
                    })
            }

            {

                Object.values(config.palettes).map(p => {
                    return (
                        <PaletteEditor
                            key={p.id}
                            palette={p}
                            onChanged={handlePaletteChange(p.id)}
                            onDelete={onDeletePalette}
                            onDuplicate={onDuplicatePalette}
                        />
                    );
                })
            }

            <Popover content={
                <Menu>
                    <MenuItem icon="layer" text="Shape" >
                        <MenuItem icon="symbol-circle" text="Circle" onClick={addShape('circle')} />
                        <MenuItem icon="symbol-circle" text="Ellipse" onClick={addShape('ellipse')} />
                        <MenuItem icon="symbol-square" text="Rectangle" onClick={addShape('rectangle')} />
                        <MenuItem icon="symbol-triangle-up" text="Triangle" onClick={addShape('triangle')} />
                        <MenuItem icon="minus" text="Line" onClick={addShape('line')} />
                        <MenuItem icon="polygon-filter" text="Path" onClick={addShape('path')} />
                        <MenuItem icon="new-text-box" text="Text" onClick={addShape('text')} />
                    </MenuItem>
                    <MenuItem icon="tint" text="Palette" onClick={addPalette} />
                </Menu>
            }>
                <Button icon="plus" text="Add..." />
            </Popover>
        </ConfiguratorContainer>
    );
}
