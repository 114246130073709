import React, { useState } from 'react';

import { PreviewContainer } from './styles';
import { Config } from '@dyssent/blankjs';
import { SizesPreview } from './SizesPreview';
import { ButtonGroup, Button, Slider } from '@blueprintjs/core';
import { SamplesPreview } from './SamplesPreview';
import { ProfilesPreview } from './ProfilesPreview';

export interface PreviewProps {
    config: Config;
}

enum PreviewMode {
    Samples,
    Sizes,
    Profile
}

export function Preview(props: PreviewProps) {
    const { config } = props;

    const [mode, setMode] = useState(PreviewMode.Samples);
    const [offset, setOffset] = useState(0);

    function handleMode(m: PreviewMode) {
        return () => setMode(m);
    }

    function onOffsetChange(value: number) {
        setOffset(value);
    }

    return (
        <PreviewContainer>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                marginBottom: 20
            }}>
                <h3 className="bp3-heading">Preview</h3>
                <ButtonGroup
                    style={{
                        marginBottom: 20
                    }}
                >
                    <Button
                        icon="list"
                        style={{ minWidth: 120 }}
                        active={mode === PreviewMode.Samples}
                        onClick={handleMode(PreviewMode.Samples)}
                    >
                        Samples
                    </Button>
                    <Button
                        icon="list-columns"
                        style={{ minWidth: 120 }}
                        active={mode === PreviewMode.Sizes}
                        onClick={handleMode(PreviewMode.Sizes)}
                    >
                        Sizes
                    </Button>
                    <Button
                        icon="user"
                        style={{ minWidth: 120 }}
                        active={mode === PreviewMode.Profile}
                        onClick={handleMode(PreviewMode.Profile)}
                    >
                        Profile
                    </Button>
                </ButtonGroup>
                <Slider
                    min={0}
                    max={50}
                    stepSize={1}
                    labelStepSize={10}
                    onChange={onOffsetChange}
                    value={offset}
                    labelRenderer={false}
                />
            </div>
            {
                mode === PreviewMode.Sizes &&
                <SizesPreview
                    config={config}
                    idOffset={offset}
                />
            }
            {
                mode === PreviewMode.Samples &&
                <SamplesPreview
                    config={config}
                    idOffset={offset}
                />
            }
            {
                mode === PreviewMode.Profile &&
                <ProfilesPreview
                    config={config}
                    idOffset={offset}
                />
            }
            
        </PreviewContainer>
    );
}
