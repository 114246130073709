import React, { useState } from 'react';
import { Button, Switch } from "@blueprintjs/core";
import GitHubButton from 'react-github-btn'

import '@blueprintjs/core/lib/css/blueprint.css';

import { Main, Header, SpanBlue, DemoHeader, DemoSampleHeader, FAQ, Footer } from './styles';
import { useInterval } from './hooks';
import { Editor } from './Editor';
import { demoConfigs } from './demoConfigs';
import { Profile } from './Editor/Preview/Profile';

const demoLayout = [
  0, 1, 1,
  1, 0, 1,
  1, 1, 1
];

const DemoBlock = React.memo((props: {without: boolean, index: number}) => {
  const { without, index } = props;
  const cfg = demoConfigs[index];
  const cols = 3;

  return (
    <DemoHeader
      cols={cols}
    >
      {
        demoLayout.map((l, li) => {
          if (l === 0) {
            return <DemoSampleHeader key={li} />;
          }

          return (
            <DemoSampleHeader
              key={li}
            >
              <Profile
                id={li}
                config={cfg}
                blank={without}
              />
            </DemoSampleHeader>
          );
        })
      }
    </DemoHeader>
  );
});

const App: React.FC = () => {
  const [dark, setDark] = useState(true);
  const [demoConfig, setDemoConfig] = useState(0);
  const [withoutBlank, setWithoutBlank] = useState(false);

  function onModeChange() {
    setDark(!dark);
  }

  function onWithoutChange() {
    setWithoutBlank(!withoutBlank);
  }

  useInterval(() => {
    setDemoConfig(
      demoConfig >= demoConfigs.length - 1 ? 0 : demoConfig + 1
    );
  }, 5000);

  return (
    <Main
      dark={dark}
      className={dark ? 'bp3-dark' : undefined}
    >
      <Button
        icon={dark ? 'flash' : 'moon'}
        minimal={true}
        onClick={onModeChange}
        style={{
          position: 'absolute',
          left: 15,
          top: 15
        }}
      >
        {dark ? 'Light' : 'Dark'} mode
      </Button>
      <div
        style={{
          position: 'absolute',
          right: 15,
          top: 15
        }}
      >
        <GitHubButton
          href="https://github.com/dyssent/blankjs"
          data-color-scheme="no-preference: dark; light: light; dark: light;"
          data-size="large"
          aria-label="Star blankjs on GitHub"
        >
            GitHub
        </GitHubButton>
      </div>

      <Header className="bp3-running-text bp3-text-large">
        <h1>Blank.JS</h1>
        <h3>
          <p><SpanBlue>Beautiful placeholders</SpanBlue> for your avatars, documents, and other lists.</p>
          <p>No server needed, <SpanBlue>works offline!</SpanBlue> Works with React.</p>
        </h3>
      </Header>
      <Switch
          large={true}
          checked={!withoutBlank}
          label={withoutBlank ? "Without Blank.JS" : "With Blank.JS"}
          onChange={onWithoutChange}
      />
      <DemoBlock
        index={demoConfig}
        without={withoutBlank}
      />
      <Header className="bp3-running-text bp3-text-large">
        <h2>How to use?</h2>
        <ol style={{ textAlign: 'left'}}>
          <li>
            Install the library:
            <pre>
              yarn add @dyssent/blankjs
            </pre>
          </li>
          <li>
            Create a style for your placeholders (the easiest using the editor below)
          </li>
          <li>
            Assign your style as default for all blanks
            <pre>
              import { '{ DefaultSettings }' } from '@dyssent/blankjs';<br />
              DefaultSettings.config = ... paste here your config ...
            </pre>
          </li>
          <li>
            Render your blank placeholder:
            <pre>
              {
                `<Blank id="john.doe.id" />`
              }
            </pre>
          </li>
        </ol>
      </Header>
      
      <Editor />
      <FAQ className="bp3-running-text bp3-text-large">
        <h2>FAQ</h2>
        <ol>
          <li>
            How does it work?
            <p className="bp3-text-muted">
              We use a deterministic randomizer, which allows to generate the same
              placeholder shape and style for the same user ID. Each render we set
              the seed to a user ID hash, guaranteeing the same output. Result is rendered
              into a 128x128 SVG viewport, but scales without artifacts after that.
            </p>
          </li>
          <li>
            What can serve as a user ID?
            <p className="bp3-text-muted">
              Anything that doesn't change throughout user's existence will work.
              If email is expected to remain permanently - it is a good option. Otherwise,
              any internal ID will work, a number, a guid, or so.
            </p>
          </li>
          <li>
            Can I use multiple styles in the same app?
            <p className="bp3-text-muted">
              Absolutely. Each component allows you to specify a custom config, which
              will result in a different output. Default config is used only when none
              is provided to an instance of a blank component. You can have as many configs
              as there are different types of entities - users, organizations, projects, etc.
              Here is an example:
            </p>
            <pre>
              {
                `<Blank id="userID" config={userStyleConfig} />\n<Blank id="orgID" config={orgStyleConfig} />`
              }
            </pre>
          </li>
          <li>
            Is it fast?
            <p className="bp3-text-muted">
              We use SVG under the hood, which has very good performance in modern browsers.
              There is no pre-compute work done, configs are not prepared, but just interpreted
              during a render pass. Plus everything is cached and will remain as is unless
              user ID or config changes on the component.
            </p>
            <p className="bp3-text-muted">
              It is generally a good rule of thumb to keep number of shapes as low as possible
              to achieve the desired style. If there are too many of them - it will have impact
              on the first render pass.
            </p>
          </li>
          <li>
            Can I use it to display actual avatars?
            <p className="bp3-text-muted">
              Sure! If you provide a URL for the avatar - it'll be used instead of the generated
              shape. Additionally, it is possible to provide a File type, which is usually helpful
              when you change an avatar and need to immediately show the preview of it before
              uploading the file.
            </p>
            <pre>
              {
                `<Blank id="userID" avatar="https://userID.avatar.jpg" />`
              }
            </pre>
          </li>
          <li>
            Can I display custom shapes and Logos?
            <p className="bp3-text-muted">
              If you use Path shape, it will accept typical SVG shape path, therefore custom logos,
              shapes, or anything else can be used. They are still customizable with style, replication,
              and other modifiers.
            </p>
          </li>
          <li>
            Is it possible to use it for other use cases?
            <p className="bp3-text-muted">
              Probably, if overflow is not hidden - it possible to use it for some backgrounds,
              or some abstract shapes on the page. Imagination is the limit.
            </p>
          </li>
          <li>
            Can I use it without React?
            <p className="bp3-text-muted">
              Unfortunately no, at the moment the library is only working for React. If you need other
              frameworks - feel free to make a pull request.
            </p>
          </li>
          <li>
            Do you provide Typescript bindings?
            <p className="bp3-text-muted">
              The library is written in typescript, so types are all available out of the box.
            </p>
          </li>
          <li>
            Can I help somehow?
            <p className="bp3-text-muted">
              Spreading the word is the best help :) Or a star on github!
            </p>
          </li>
        </ol>
      </FAQ>
      <Footer
        className="bp3-running-text"
      >
        <div><span className="bp3-text-muted">Built by <span role="img" aria-label="roo">🦘</span>and <span role="img" aria-label="koala">🐨</span> in 🇦🇺</span></div>
      </Footer>   
    </Main>
  );
}

export default App;
