import React from 'react';

import { ProfileContainer } from './styles';
import { Config, Blank } from '@dyssent/blankjs';
import { getName } from '../Configurator';
import { Icon } from '@blueprintjs/core';

export interface ProfileProps {
    config: Config;
    id: number;
    blank?: boolean;
}

export function Profile(props: ProfileProps) {
    const { id, config, blank } = props;
    const name = getName(id);
    const offline = id % 3 === 0;
    return (
        <ProfileContainer
            className="blank-shadow"
        >
            {
                blank ?
                    <div
                        className="blank blank-circle"
                        style={{
                            width: 48,
                            height: 48,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Icon icon="person" iconSize={36} />
                    </div>
                :
                    <Blank
                        className="blank blank-circle"
                        id={id}
                        config={config}
                        style={{
                            width: 48,
                            height: 48
                        }}
                        title={name}
                    />
            }
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr'
                }}
            >
                <span>{name}</span>
                <div className="bp3-text-muted" style={{display: 'flex', alignItems: 'center'}}>
                    {offline ? 'Offline' : 'Online'}
                    {
                        !offline &&
                        <div style={{
                            backgroundColor: 'green',
                            borderRadius: '50%',
                            width: 5,
                            height: 5,
                            marginLeft: 5
                        }} />
                    }
                </div>
            </div>
        </ProfileContainer>
    );
}
