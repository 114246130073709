import React from 'react';
import { Replicate, Range } from '@dyssent/blankjs';
import { RangeEdit } from './RangeEdit';

export interface ReplicationEditProps {
    replication: Replicate;
    onUpdated: (rep: Replicate) => void;
}

export function ReplicationEdit(props: ReplicationEditProps) {
    const { replication, onUpdated } = props;

    function onCountUpdate(range: Range) {
        onUpdated({
            ...replication,
            count: range
        });
    }

    return (
        <RangeEdit title="Replicas" range={replication.count} onUpdated={onCountUpdate} min={0} />
    );
}
