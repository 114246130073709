import React, { useState } from 'react';

import { Shape, Style, Palette, Modifiers } from '@dyssent/blankjs';
import { Card, Button, Divider, Collapse, Icon } from '@blueprintjs/core';
import { ModifiersEdit } from './ModifiersEdit';
import { ShapeSpecificsEdit } from './ShapeSpecificsEdit';
import { StyleEdit } from './StyleEdit';

export interface ShapeEditorProps {
    shape: Shape;
    userPalettes: {[key: string]: Palette};
    onChanged: (shape: Shape) => void;
    onDelete: (shape: Shape) => void;
    onDuplicate: (shape: Shape) => void;
    onMoveDown: (shape: Shape) => void;
    onMoveUp: (shape: Shape) => void;
}

export function ShapeEditor(props: ShapeEditorProps) {
    const {
        shape,
        onDelete,
        onChanged,
        onMoveUp,
        onMoveDown,
        userPalettes,
        onDuplicate
    } = props;

    const [open, setOpen] = useState(false);

    function handleDelete() {
        onDelete(shape);
    }

    function handleDuplicate() {
        onDuplicate(shape);
    }

    function handleMoveDown() {
        onMoveDown(shape);
    }

    function handleMoveUp() {
        onMoveUp(shape);
    }


    function onToggleCollapse() {
        setOpen(!open);
    }

    function onModsChanged(mods: Modifiers) {
        onChanged({
            ...shape,
            modifiers: mods
        });
    }

    function onStyleChanged(style: Style) {
        onChanged({
            ...shape,
            style
        });
    }

    function onSpecificsChanged(shape: Shape) {
        onChanged(shape);
    }

    return (
        <div>
            <Card
                elevation={3}
                style={{
                    padding: 20,
                    marginBottom: 20
                    // backgroundColor: '#394b59'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <h5
                        className="bp3-text-muted bp3-heading"
                        style={{ cursor: 'pointer' }}
                        onClick={onToggleCollapse}>Shape: <b>{shape.type} <Icon icon={open ? "double-chevron-up" : 'double-chevron-down'} /></b>
                    </h5>
                    <div>
                        <Button icon="arrow-down" minimal={true} onClick={handleMoveDown} />
                        <Button icon="arrow-up" minimal={true} onClick={handleMoveUp} />
                        <Button icon="duplicate" minimal={true} onClick={handleDuplicate} />
                        <Button icon="trash" minimal={true} onClick={handleDelete} />
                    </div>
                </div>
                <Collapse isOpen={open}>
                    <Divider />
                    <ShapeSpecificsEdit shape={shape} onChanged={onSpecificsChanged}/>
                    <Divider />
                    <div>
                        <StyleEdit
                            style={shape.style}
                            noFill={shape.type === 'line'}
                            userPalettes={userPalettes}
                            onChanged={onStyleChanged}
                        />
                        <ModifiersEdit
                            mods={shape.modifiers}
                            onChanged={onModsChanged}
                        />
                    </div>
                </Collapse>
            </Card>
        </div>
    );
}