import React from 'react';
import { Range, Scale } from '@dyssent/blankjs';
import { RangeEdit } from './RangeEdit';
import { OptionalRangeEdit } from './OptionalRangeEdit';

export interface ScaleEditProps {
    scale?: Scale;
    onUpdated: (scale: Scale) => void;
}

const defaultScale: Scale = {
    modifier: 'scale',
    x: {left: 1, right: 1},
    y: {left: 1, right: 1}
};

export function ScaleEdit(props: ScaleEditProps) {
    const { scale = defaultScale, onUpdated } = props;

    function onXUpdate(range: Range) {
        onUpdated({
            ...scale,
            x: range
        });
    }

    function onYUpdate(range?: Range) {
        onUpdated({
            ...scale,
            y: range
        });
    }

    return (
        <div>
            <span>Scale: </span>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridGap: 20,
                    paddingLeft: 20,
                    paddingRight: 20
                }}
            >
                <RangeEdit title="X" muted={true} range={scale.x} onUpdated={onXUpdate} />
                <OptionalRangeEdit title="Y" muted={true} range={scale.y} onChanged={onYUpdate} />
            </div>
        </div>
    );
}
