import React from 'react';
import { Shape } from '@dyssent/blankjs';
import { CircleEdit } from './CircleEdit';
import { EllipseEdit } from './EllipseEdit';
import { RectEdit } from './RectEdit';
import { TriangleEdit } from './TriangleEdit';
import { LineEdit } from './LineEdit';
import { PathEdit } from './PathEdit';
import { TextEdit } from './TextEdit';

export interface ShapeSpecificsEditProps {
    shape: Shape;
    onChanged: (shape: Shape) => void;
}

export function ShapeSpecificsEdit(props: ShapeSpecificsEditProps) {
    const { shape, onChanged } = props;

    switch (shape.type) {
        case 'circle': return <CircleEdit circle={shape} onChanged={onChanged}/>;
        case 'ellipse': return <EllipseEdit ellipse={shape} onChanged={onChanged} />;
        case 'rectangle': return <RectEdit rect={shape} onChanged={onChanged} />;
        case 'triangle': return <TriangleEdit triangle={shape} onChanged={onChanged} />;
        case 'line': return <LineEdit line={shape} onChanged={onChanged} />;
        case 'path': return <PathEdit path={shape} onChanged={onChanged} />;
        case 'text': return <TextEdit text={shape} onChanged={onChanged} />;
        default:
            return null;
    }
}
