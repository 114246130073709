import React from 'react';
import { Range, Translate } from '@dyssent/blankjs';
import { RangeEdit } from './RangeEdit';

export interface TranslateEditProps {
    translate?: Translate;
    onUpdated: (tr: Translate) => void;
}

const defaultTranslate: Translate = {
    modifier: 'translate',
    x: {left: 0, right: 0},
    y: {left: 0, right: 0}
};

export function TranslateEdit(props: TranslateEditProps) {
    const { translate = defaultTranslate, onUpdated } = props;

    function onXUpdate(range: Range) {
        onUpdated({
            ...translate,
            x: range
        });
    }

    function onYUpdate(range: Range) {
        onUpdated({
            ...translate,
            y: range
        });
    }

    return (
        <div>
            <span>Translate: </span>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridGap: 20,
                    paddingLeft: 20,
                    paddingRight: 20
                }}
            >
                <RangeEdit title="X" muted={true} range={translate.x} onUpdated={onXUpdate} />
                <RangeEdit title="Y" muted={true} range={translate.y} onUpdated={onYUpdate} />
            </div>
        </div>
    );
}
