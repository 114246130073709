import React from 'react';
import { Range, Circle } from '@dyssent/blankjs';
import { RangeEdit } from './RangeEdit';

export interface CircleEditProps {
    circle: Circle;
    onChanged: (circle: Circle) => void;
}

export function CircleEdit(props: CircleEditProps) {
    const { circle, onChanged } = props;

    function onRadiusUpdate(range: Range) {
        onChanged({
            ...circle,
            radius: range
        });
    }

    return (
        <RangeEdit title="Radius" range={circle.radius} onUpdated={onRadiusUpdate} min={0} />
    );
}
