import React from 'react';
import { Switch } from '@blueprintjs/core';
import { Range } from '@dyssent/blankjs';
import { RangeEdit } from './RangeEdit';

export interface OptionalRangeEditProps {
    title?: string;
    muted?: boolean;
    range?: Range;
    min?: number;
    max?: number;
    onChanged: (range?: Range) => void;
}

const defaultRange: Range = {
    left: 0,
    right: 32
}

export function OptionalRangeEdit(props: OptionalRangeEditProps) {
    const { min, max, title, muted, range, onChanged } = props;

    function onRangeChanged(r: Range) {
        onChanged(r);
    }

    function onSwitchChange() {
        if (range) {
            onChanged(undefined);
        } else {
            onChanged(
                range ? range : {...defaultRange}
            )
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                height: 32
            }}
        >
            <Switch
                checked={range ? true : false}
                onChange={onSwitchChange}
                label={title}
                style={{
                    marginBottom: 0,
                    marginRight: 10,
                    width: 100
                }}
            />
            {
                range &&
                <RangeEdit
                    muted={muted}
                    range={range}
                    onUpdated={onRangeChanged}
                    min={min}
                    max={max}
                />
            }
            
        </div>
    );
}