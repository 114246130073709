import React from 'react';
import { Range, Triangle } from '@dyssent/blankjs';
import { RangeEdit } from './RangeEdit';
import { OptionalRangeEdit } from './OptionalRangeEdit';

export interface TriangleEditProps {
    triangle: Triangle;
    onChanged: (tri: Triangle) => void;
}

export function TriangleEdit(props: TriangleEditProps) {
    const { triangle, onChanged } = props;

    function onLengthUpdate(length1: Range) {
        onChanged({
            ...triangle,
            length1
        });
    }

    function onLength2Update(length2?: Range) {
        onChanged({
            ...triangle,
            length2
        });
    }

    function onLength3Update(length3?: Range) {
        onChanged({
            ...triangle,
            length3
        });
    }

    return (
        <div
            style={{
                marginLeft: 20
            }}
        >
            <RangeEdit title="Length" muted={true} range={triangle.length1} onUpdated={onLengthUpdate} min={0}/>
            <OptionalRangeEdit title="Length 2" muted={true} range={triangle.length2} onChanged={onLength2Update} min={0}/>
            <OptionalRangeEdit title="Length 3" muted={true} range={triangle.length3} onChanged={onLength3Update} min={0}/>
        </div>
    );
}
