import React from 'react';
import { Range, Rectangle } from '@dyssent/blankjs';
import { RangeEdit } from './RangeEdit';
import { OptionalRangeEdit } from './OptionalRangeEdit';

export interface RectEditProps {
    rect: Rectangle;
    onChanged: (rect: Rectangle) => void;
}

export function RectEdit(props: RectEditProps) {
    const { rect, onChanged } = props;

    function onWidthUpdate(width: Range) {
        onChanged({
            ...rect,
            width
        });
    }

    function onHeightUpdate(height?: Range) {
        onChanged({
            ...rect,
            height
        });
    }

    function onBorderRadiusUpdate(borderRadius?: Range) {
        onChanged({
            ...rect,
            borderRadius
        });
    }

    return (
        <div
            style={{
                marginLeft: 20
            }}
        >
            <RangeEdit title="Width" muted={true} range={rect.width} onUpdated={onWidthUpdate} min={0} />
            <OptionalRangeEdit title="Height" range={rect.height} onChanged={onHeightUpdate} min={0} />
            <OptionalRangeEdit title="Border" range={rect.borderRadius} onChanged={onBorderRadiusUpdate} min={0} />
        </div>
    );
}
