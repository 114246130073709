import React from 'react';
import { Range, Ellipse } from '@dyssent/blankjs';
import { RangeEdit } from './RangeEdit';

export interface EllipseEditProps {
    ellipse: Ellipse;
    onChanged: (ellipse: Ellipse) => void;
}

export function EllipseEdit(props: EllipseEditProps) {
    const { ellipse, onChanged } = props;

    function onWidthUpdate(width: Range) {
        onChanged({
            ...ellipse,
            width
        });
    }

    function onHeightUpdate(width: Range) {
        onChanged({
            ...ellipse,
            width
        });
    }

    return (
        <>
            <RangeEdit title="Width" range={ellipse.width} onUpdated={onWidthUpdate} min={0} />
            <RangeEdit title="Height" range={ellipse.height} onUpdated={onHeightUpdate} min={0} />
        </>
    );
}
