import React from 'react';

import { ProfilesPreviewContainer } from './styles';
import { Config } from '@dyssent/blankjs';
import { Profile } from './Profile';

export interface ProfilesPreviewProps {
    config: Config;
    idOffset: number;
}

export function ProfilesPreview(props: ProfilesPreviewProps) {
    const { config, idOffset } = props;
    const previews = 16;
    const profiles: JSX.Element[] = [];
    for (let i = 0; i < previews; i++) {
        profiles.push(
            <Profile
                id={i + idOffset}
                key={i + idOffset}
                config={config}
            />
        );
    }
    return (
        <ProfilesPreviewContainer>
            {
                profiles
            }
        </ProfilesPreviewContainer>
    );
}
