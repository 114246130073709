import React from 'react';
import { ControlGroup, FormGroup, NumericInput } from '@blueprintjs/core';
import { Range } from '@dyssent/blankjs';

export interface RangeEditProps {
    title?: string;
    muted?: boolean;
    range: Range;
    min?: number;
    max?: number;
    onUpdated: (range: Range) => void;
}

export function RangeEdit(props: RangeEditProps) {
    const { min, max, title, muted, range, onUpdated } = props;

    function onLeftChange(valueAsNumber: number, valueAsString: string) {
        onUpdated({
            ...range,
            left: valueAsNumber
        });
    }

    function onRightChange(valueAsNumber: number, valueAsString: string) {
        onUpdated({
            ...range,
            right: valueAsNumber
        });
    }

    function onStepChange(valueAsNumber: number, valueAsString: string) {
        onUpdated({
            ...range,
            step: valueAsString === '' ? undefined : valueAsNumber
        });
    }

    return (
        <FormGroup
            label={<small className={muted ? 'bp3-text-muted' : undefined}>{title}</small>}
        >
            <ControlGroup
                fill={true}
                style={{
                    paddingLeft: muted ? undefined : 20,
                    paddingRight: muted ? undefined : 20
                }}
            >
                <NumericInput
                    type="number"
                    step="any"
                    placeholder="Min"
                    min={min}
                    max={max}
                    value={range.left.toString()}
                    onValueChange={onLeftChange}
                    buttonPosition="none"
                    fill={true}
                />
                <NumericInput
                    type="number"
                    step="any"
                    placeholder="Max"
                    min={min}
                    max={max}
                    value={range.right.toString()}
                    onValueChange={onRightChange}
                    buttonPosition="none"
                    fill={true}
                />
                <NumericInput
                    type="number"
                    step="any"
                    placeholder="Step"
                    value={range.step ? range.step.toString() : ''}
                    onValueChange={onStepChange}
                    buttonPosition="none"
                    fill={true}
                />
            </ControlGroup>
        </FormGroup>
    );
}