import React from 'react';

import { SamplesPreviewContainer } from './styles';
import { Config, Blank } from '@dyssent/blankjs';
import { getName } from '../Configurator';

export interface PreviewProps {
    config: Config;
    idOffset: number;
}

export function SamplesPreview(props: PreviewProps) {
    const { config, idOffset } = props;
    const previews = 32;
    const samples: JSX.Element[] = [];
    for (let i = 0; i < previews; i++) {
        const name = getName(i);
        samples.push(
            <Blank
                className="blank blank-square blank-shadow"
                key={i + idOffset}
                id={i + idOffset}
                config={config}
                style={{
                    width: 64,
                    height: 64
                }}
                title={name}
            />
        );
    }
    return (
        <SamplesPreviewContainer>
            {
                samples
            }
        </SamplesPreviewContainer>
    );
}
