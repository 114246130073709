import styled from 'styled-components';

export const PreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: start;
    align-items: center;
`;

export const SizesPreviewContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
`;

export const SizesPreviewRow = styled.div`
    display: grid;
    grid-template-columns: 8fr 4fr 2fr 1fr;
    grid-gap: 20px;
    align-items: end;
`;

export const SamplesPreviewContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
`;

export const ProfileContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;
    padding: 10px;
    align-items: center;
`;

export const ProfilesPreviewContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
`;
