import React, { useState } from 'react';

import { Card, Button, Divider, Collapse, Icon } from '@blueprintjs/core';
import { Config } from '@dyssent/blankjs';

export interface ConfigPreviewProps {
    config: Config;
}

const copyToClipboard = (str: string) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export function ConfigPreview(props: ConfigPreviewProps) {
    const { config } = props;

    const [open, setOpen] = useState(true);

    function onToggleCollapse() {
        setOpen(!open);
    }

    function onCopy() {
        copyToClipboard(JSON.stringify(config, undefined , 2));
    }

    return (
        <Card
            elevation={3}
            style={{
                padding: 20,
                margin: 20,
                width: '100%',
                maxWidth: 1024
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <h5
                    className="bp3-text-muted bp3-heading"
                    style={{ cursor: 'pointer' }}
                    onClick={onToggleCollapse}>Config Code <Icon icon={open ? "double-chevron-up" : 'double-chevron-down'} />
                </h5>
                <div>
                    <Button icon="duplicate" minimal={true} onClick={onCopy} />
                </div>
            </div>
            <Collapse isOpen={open}>
                <Divider />
                <code className="bp3-running-text">
                    <pre
                        style={{
                            overflow: 'hidden'
                        }}
                    >
                        {
                            JSON.stringify(config, undefined, 2)
                        }
                    </pre>
                </code>
            </Collapse>
        </Card>
    );
}