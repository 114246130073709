import React from 'react';
import { Style, Range, Color, Palette } from '@dyssent/blankjs';
import { RangeEdit } from './RangeEdit';
import { ColorEdit } from './ColorEdit';
import { OptionalRangeEdit } from './OptionalRangeEdit';

export interface StyleEditProps {
    style: Style;
    noFill?: boolean;
    userPalettes: {[key: string]: Palette};
    onChanged: (st: Style) => void;
}

const defaultStrokeWidth: Range = {
    left: 0,
    right: 0
};

export function StyleEdit(props: StyleEditProps) {
    const { style, noFill, userPalettes, onChanged } = props;
    const strokeWidth = style.strokeWidth || defaultStrokeWidth;

    function onStrokeWidth(range: Range) {
        onChanged({
            ...style,
            strokeWidth: range
        });
    }

    function onOpacityChanged(opacity?: Range) {
        onChanged({
            ...style,
            opacity
        });
    }

    function onFillChanged(color?: Color) {
        onChanged({
            ...style,
            fill: color
        });
    }

    function onStrokeChanged(color?: Color) {
        onChanged({
            ...style,
            stroke: color
        });
    }

    return (
        <div
            style={{
                marginTop: 20
            }}
        >
            <h5 className="bp3-text-muted bp3-heading">Style:</h5>
            <div
                style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {
                    !noFill &&
                    <ColorEdit
                        color={style.fill}
                        title="Fill"
                        userPalettes={userPalettes}
                        onChanged={onFillChanged}
                    />
                }
                <ColorEdit
                    color={style.stroke}
                    title="Stroke"
                    userPalettes={userPalettes}
                    onChanged={onStrokeChanged}
                />
                {
                    style.stroke &&
                    <RangeEdit
                        title="Stroke Width"
                        muted={true}
                        range={strokeWidth}
                        onUpdated={onStrokeWidth}
                        min={0}
                    />
                }
                <OptionalRangeEdit
                        title="Opacity"
                        muted={true}
                        range={style.opacity}
                        onChanged={onOpacityChanged}
                        min={0}
                        max={1}
                />
            </div>
        </div>
    );
}
