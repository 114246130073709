import { Config } from '@dyssent/blankjs';

const sample1: Config = {
  "shapes": [
    {
      "type": "rectangle",
      "width": {
        "left": 128,
        "right": 128
      },
      "style": {
        "fill": {
          "type": "custom",
          "value": "rgba(255, 255, 255, 1)"
        },
        "strokeWidth": {
          "left": 2,
          "right": 3
        }
      },
      "modifiers": {
        "replication": {
          "count": {
            "left": 1,
            "right": 5
          }
        },
        "transforms": {
          "translate": {
            "modifier": "translate",
            "x": {
              "left": 0,
              "right": 0,
              "step": 8
            },
            "y": {
              "left": 0,
              "right": 0,
              "step": 8
            }
          },
          "rotate": {
            "modifier": "rotate",
            "angle": {
              "left": 0,
              "right": 0
            }
          },
          "scale": {
            "modifier": "scale",
            "x": {
              "left": 1,
              "right": 1
            }
          }
        }
      }
    },
    {
      "type": "line",
      "length": {
        "left": 256,
        "right": 512,
        "step": 2
      },
      "style": {
        "strokeWidth": {
          "left": 64,
          "right": 64
        },
        "stroke": {
          "type": "palette",
          "palette": {
            "type": "provided",
            "id": "1573082461923"
          }
        }
      },
      "modifiers": {
        "replication": {
          "count": {
            "left": 2,
            "right": 5
          }
        },
        "transforms": {
          "translate": {
            "modifier": "translate",
            "x": {
              "left": -128,
              "right": 128,
              "step": 32
            },
            "y": {
              "left": 0,
              "right": 0
            }
          },
          "rotate": {
            "modifier": "rotate",
            "angle": {
              "left": 45,
              "right": 45,
              "step": 90
            },
            "origin": {
              "x": {
                "left": 64,
                "right": 64
              },
              "y": {
                "left": 64,
                "right": 64
              }
            }
          },
          "scale": {
            "modifier": "scale",
            "x": {
              "left": 1,
              "right": 1
            }
          }
        }
      }
    }
  ],
  "palettes": {
    "1573082461923": {
      "id": "1573082461923",
      "type": "values",
      "name": "SlackColors",
      "values": [
        "rgba(54, 197, 240, 1)",
        "rgba(46, 182, 125, 1)",
        "rgba(224, 30, 90, 1)",
        "rgba(236, 178, 46, 1)"
      ]
    }
  }
};

const sample2: Config = {
  "shapes": [
    {
      "type": "ellipse",
      "width": {
        "left": 4,
        "right": 10
      },
      "height": {
        "left": 5,
        "right": 11,
        "step": 2
      },
      "style": {
        "strokeWidth": {
          "left": 4,
          "right": 8
        },
        "stroke": {
          "type": "palette",
          "palette": {
            "type": "builtin",
            "id": "cobalt5"
          }
        },
        "opacity": {
          "left": 0.2,
          "right": 0.5
        }
      },
      "modifiers": {
        "replication": {
          "count": {
            "left": 2,
            "right": 4
          }
        },
        "transforms": {
          "translate": {
            "modifier": "translate",
            "x": {
              "left": -64,
              "right": 64,
              "step": 8
            },
            "y": {
              "left": -64,
              "right": 64,
              "step": 8
            }
          },
          "scale": {
            "modifier": "scale",
            "x": {
              "left": 1,
              "right": 1
            }
          }
        }
      }
    },
    {
      "type": "text",
      "size": {
        "left": 36,
        "right": 64,
        "step": 2
      },
      "style": {
        "fill": {
          "type": "palette",
          "palette": {
            "type": "builtin",
            "id": "orange5"
          }
        },
        "strokeWidth": {
          "left": 1,
          "right": 4
        }
      },
      "modifiers": {
        "replication": {
          "count": {
            "left": 2,
            "right": 3
          }
        },
        "transforms": {
          "translate": {
            "modifier": "translate",
            "x": {
              "left": -32,
              "right": 32,
              "step": 8
            },
            "y": {
              "left": -32,
              "right": 32,
              "step": 8
            }
          },
          "scale": {
            "modifier": "scale",
            "x": {
              "left": 1,
              "right": 1
            }
          },
          "rotate": {
            "modifier": "rotate",
            "origin": {
              "x": {
                "left": 64,
                "right": 64
              },
              "y": {
                "left": 64,
                "right": 64
              }
            },
            "angle": {
              "left": -45,
              "right": 45,
              "step": 15
            }
          }
        }
      }
    }
  ],
  "palettes": {}
};

const sample3: Config = {
  "shapes": [
    {
      "type": "line",
      "length": {
        "left": 28,
        "right": 260,
        "step": 2
      },
      "style": {
        "strokeWidth": {
          "left": 1,
          "right": 3
        },
        "stroke": {
          "type": "palette",
          "palette": {
            "type": "builtin",
            "id": "turq5"
          }
        }
      },
      "modifiers": {
        "replication": {
          "count": {
            "left": 5,
            "right": 15
          }
        },
        "transforms": {
          "translate": {
            "modifier": "translate",
            "x": {
              "left": -64,
              "right": 64,
              "step": 8
            },
            "y": {
              "left": -64,
              "right": 64,
              "step": 8
            }
          },
          "rotate": {
            "modifier": "rotate",
            "angle": {
              "left": 0,
              "right": 360
            },
            "origin": {
              "x": {
                "left": 64,
                "right": 64
              },
              "y": {
                "left": 64,
                "right": 64
              }
            }
          },
          "scale": {
            "modifier": "scale",
            "x": {
              "left": 1,
              "right": 1
            }
          }
        }
      }
    }
  ],
  "palettes": {}
};

const sample4: Config = {
  "shapes": [
    {
      "type": "path",
      "style": {
        "strokeWidth": {
          "left": 4,
          "right": 8
        },
        "stroke": {
          "type": "palette",
          "palette": {
            "type": "builtin",
            "id": "nine"
          }
        }
      },
      "modifiers": {
        "replication": {
          "count": {
            "left": 1,
            "right": 1
          }
        },
        "transforms": {
          "translate": {
            "modifier": "translate",
            "x": {
              "left": 0,
              "right": 64,
              "step": 8
            },
            "y": {
              "left": 0,
              "right": 32,
              "step": 8
            }
          },
          "scale": {
            "modifier": "scale",
            "x": {
              "left": 1,
              "right": 1
            }
          }
        }
      },
      "data": "M71 74.6c-0.1-5.4-4.2-7.7-8.8-9.6 -3.6-1.5-12.1-2.2-13.8-6.2 -0.8-1.9-0.2-3.1 0.1-5.8 7 0.2 17.7-5.3 19-10.4 0.7-2.5-2.8-4.4-4.2-6 -1.9-2-3.3-4.5-4.3-7.1 -2.2-5.6-0.2-11.2-2.1-16.7C54.2 5.1 46.5 2 40 2l0 0c0 0 0 0 0 0s0 0 0 0l0 0c-6.5 0-14.2 3.1-16.8 10.7 -1.9 5.5 0 11.1-2.1 16.7 -1 2.6-2.4 5-4.3 7.1 -1.4 1.6-4.9 3.5-4.2 6 1.3 5.1 12.1 10.6 19 10.4 0.3 2.7 0.9 4 0.1 5.9 -1.7 4-10.2 4.7-13.8 6.2 -4.6 2-8.7 4.2-8.8 9.6 0 2.8 0 3.4 3.5 3.4H67.5C71 78 71 77.4 71 74.6z"
    }
  ],
  "palettes": {}
};

const sample5: Config = {
  "shapes": [
    {
      "type": "rectangle",
      "width": {
        "left": 128,
        "right": 128,
        "step": 2
      },
      "style": {
        "fill": {
          "type": "palette",
          "palette": {
            "type": "builtin",
            "id": "nine"
          }
        },
        "strokeWidth": {
          "left": 2,
          "right": 3
        }
      },
      "modifiers": {
        "replication": {
          "count": {
            "left": 1,
            "right": 1
          }
        },
        "transforms": {
          "translate": {
            "modifier": "translate",
            "x": {
              "left": 0,
              "right": 0
            },
            "y": {
              "left": 0,
              "right": 0
            }
          }
        }
      }
    },
    {
      "type": "circle",
      "radius": {
        "left": 32,
        "right": 96
      },
      "style": {
        "fill": {
          "type": "custom",
          "value": "rgba(255, 255, 255, 1)"
        },
        "opacity": {
          "left": 0.05,
          "right": 0.2
        }
      },
      "modifiers": {
        "replication": {
          "count": {
            "left": 1,
            "right": 2
          }
        },
        "transforms": {
          "translate": {
            "modifier": "translate",
            "x": {
              "left": -64,
              "right": 64,
              "step": 8
            },
            "y": {
              "left": -64,
              "right": 64,
              "step": 8
            }
          },
          "scale": {
            "modifier": "scale",
            "x": {
              "left": 1,
              "right": 1
            }
          }
        }
      }
    },
    {
      "type": "text",
      "size": {
        "left": 64,
        "right": 64
      },
      "style": {
        "fill": {
          "type": "custom",
          "value": "rgba(255, 255, 255, 1)"
        },
        "strokeWidth": {
          "left": 1,
          "right": 4
        }
      },
      "modifiers": {
        "replication": {
          "count": {
            "left": 1,
            "right": 1
          }
        },
        "transforms": {
        }
      }
    }
  ],
  "palettes": {}
};

export const demoConfigs: Config[] = [
  sample1,
  sample2,
  sample3,
  sample4,
  sample5
];
