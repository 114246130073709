import React, { useState } from 'react';

import { Palette } from '@dyssent/blankjs';
import { Card, Button, Divider, Collapse, Icon, EditableText, Popover } from '@blueprintjs/core';
import { SketchPicker, ColorResult } from 'react-color';

export interface PaletteEditorProps {
    palette: Palette;
    onChanged: (palette: Palette) => void;
    onDelete: (palette: Palette) => void;
    onDuplicate: (palette: Palette) => void;
}

export function PaletteEditor(props: PaletteEditorProps) {
    const {
        palette,
        onDelete,
        onChanged,
        onDuplicate
    } = props;

    const [open, setOpen] = useState(false);

    function handleDelete() {
        onDelete(palette);
    }

    function handleDuplicate() {
        onDuplicate(palette);
    }

    function onToggleCollapse() {
        setOpen(!open);
    }

    function onNameChange(value: string) {
        onChanged({
            ...palette,
            name: value
        });
    }

    function onAddColor() {
        onChanged({
            ...palette,
            values: [
                ...palette.values,
                '#ffffff'
            ]
        });
    }

    function onChangeColor(index: number) {
        return (c: ColorResult) => {
            onChanged({
                ...palette,
                values: palette.values.map((v, vi) => vi === index ? `rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${c.rgb.a})` : v)
            });
        };
    }

    return (
        <div>
            <Card
                elevation={3}
                style={{
                    padding: 20,
                    marginBottom: 20
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <h5
                        className="bp3-text-muted bp3-heading"
                        style={{ cursor: 'pointer' }}
                        onClick={onToggleCollapse}
                    >
                            Palette: <EditableText value={palette.name} onChange={onNameChange} />
                            <Icon icon={open ? "double-chevron-up" : 'double-chevron-down'} />
                    </h5>
                    <div>
                        <Button icon="duplicate" minimal={true} onClick={handleDuplicate} />
                        <Button icon="trash" minimal={true} onClick={handleDelete} />
                    </div>
                </div>
                <Collapse isOpen={open}>
                    <Divider />
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap'
                        }}
                    >
                        {
                            palette.values.map((v,vi) => {
                                return (
                                    <Popover
                                        key={vi}
                                        content={
                                            <SketchPicker
                                                color={v}
                                                onChangeComplete={onChangeColor(vi)}
                                            />
                                        }
                                    >
                                        <Button icon={<Icon color={v} icon="tint" />} style={{ marginRight: 5, marginBottom: 5}} />
                                    </Popover>   
                                );
                            })
                        }
                    </div>
                    <Button icon="plus" onClick={onAddColor} />
                </Collapse>
            </Card>
        </div>
    );
}